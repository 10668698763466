import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState(false);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false); // State to track submission status


  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
     // Prevent multiple submissions
     if (submitting) return;

     setSubmitting(true); // Disable further submissions
    try {
      // Ensure reCAPTCHA is ready before execution
    window.grecaptcha.ready(async () => {
      console.log("reCAPTCHA is ready.");
    // Get reCAPTCHA token
    const token = await window.grecaptcha.execute("6LfNWpUqAAAAALaBNW8IaHPr0CwXmZQ3rNltumto", { action: "submit" });

    if (!token) {
      setError("reCAPTCHA verification failed. Please try again.");
      setSubmitting(false); // Re-enable the button
      return;
    }
    // Include token in form data
    const emailData = new FormData(form.current);
    emailData.append("g-recaptcha-response", token);

    emailjs
      .sendForm("service_cn9l2b7", "template_x0alr9j", form.current, {
        publicKey: "NbszO-X4dv1Pnq6Hz",
      })
      .then(
        () => {
          setFormStatus(true);
          setName("");
          setEmail("");
          setMessage("");
          setTimeout(() => setFormStatus(false), 3000);
          setSubmitting(false); // Re-enable the button

        },
        (error) => {
          console.log("FAILED...", error.text);
          setError("Failed to send the email. Please try again.");
          setSubmitting(false); // Re-enable the button
        }
      );
    });
    } catch (err) {
      console.error("Error with reCAPTCHA:", err);
      setError("Unexpected error occurred. Please try again.");
      setSubmitting(false); // Re-enable the button

    }
  };
  // Llamada a la función enviarFormulario con los datos del formulario
  return (
    <section className="section" id="contact">
      <div className="container">
        <div className="row mb-5 align-items-end">
          <div className="col-md-6" data-aos="fade-up">
            <h2 className="section-heading text-left">{t("lets_talk")}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 ms-auto order-2" data-aos="fade-up">
            <ul className="list-unstyled">
              <li className="mb-3">
                <strong className="d-block mb-1">{t("address")}</strong>
                <span>Silvio Ruggieri 2700, CABA, Argentina</span>
              </li>
              <li className="mb-3">
                <strong className="d-block mb-1">{t("email")}</strong>
                <span>
                  <a href="mailto:hello@dropitlab.com">hello@dropitlab.com</a>
                </span>
              </li>
            </ul>
            <p className="social">
              <a
                href="https://www.linkedin.com/company/92569863/admin/feed/posts/"
                target="_blank"
              >
                <span className="bi bi-linkedin" />
              </a>
              {/*<a
                href="https://www.linkedin.com/company/92569863/admin/feed/posts/"
                target="_blank"
              >
                <span className="bi bi-whatsapp" />
              </a> */}
            </p>
          </div>
          <div className="col-md-6 mb-5 mb-md-0" data-aos="fade-up">
            <form ref={form} onSubmit={sendEmail} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="name">{t("name")}</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder={t("enter_name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="name"
                    required
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <label htmlFor="name">{t("email")}</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder={t("enter_email")}
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="col-md-12 form-group mt-3">
                  <label htmlFor="name">{t("message")}</label>
                  <textarea
                    className="form-control"
                    name="message"
                    value={message}
                    required
                    rows={10}
                    placeholder={t("enter_your_message")}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <div
                    className={
                      formStatus ? "sent-message d-block" : "sent-message"
                    }
                  >
                    Your message has been sent. Thank you!
                  </div>
                </div>

              {/* Display form status */}
              {formStatus && <p>Your message has been sent. Thank you!</p>}
              {error && <p className="error">{error}</p>}

                <div className="col-md-6 form-group">
                  <button
                    type="submit"
                    className="btn btn-primary d-block w-100 mt-4"
                  >
                   {submitting ? t("sending") : t("send")}

                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
